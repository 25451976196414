module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 320px)","mobile":"(max-width: 768px)","md":"(max-width: 1024px)","l":"(max-width: 1536px)","xl":"(max-width: 1900px)","portrait":"(orientation: portrait)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pizzarte","short_name":"Pizzarte","start_url":"/","background_color":"#FF0000","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b6de672510700b5d264f21e723a01faf"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["pt","en"],"defaultLanguage":"pt","siteUrl":"https://pizzarte.com","generateDefaultLanguagePage":false,"i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/blog/:uid","getLanguageFromPath":true,"excludeLanguages":["es"]},{"matchPath":"/preview","languages":["en"]},{"matchPath":"/:lang?/menu/","getLanguageFromPath":false},{"matchPath":"/:lang?/menu/(.*)","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-translate-urls/gatsby-browser.js'),
      options: {"plugins":[],"translations":{"en":{"urls.index":"/","urls.menu":"menu","urls.entradas":"starters","urls.saladas":"salads","urls.massas":"pasta","urls.sobremesas":"desserts","urls.bebidas":"drinks","urls.galeria":"gallery","urls.contactos":"contacts"},"pt":{"urls.index":"/","urls.menu":"menu","urls.entradas":"entradas","urls.starters":"entradas","urls.entrees":"entradas","urls.saladas":"saladas","urls.salads":"saladas","urls.salades":"saladas","urls.massas":"massas","urls.pasta":"massas","urls.pate":"massas","urls.sobremesas":"sobremesas","urls.desserts":"sobremesas","urls.postres":"sobremesas","urls.boissons":"bebidas","urls.bebidas":"bebidas","urls.drinks":"bebidas","urls.galeria":"galeria","urls.contactos":"contactos"},"es":{"urls.index":"/","urls.menu":"menu","urls.entradas":"entradas","urls.starters":"entradas","urls.entrees":"entradas","urls.saladas":"saladas","urls.salads":"saladas","urls.salades":"saladas","urls.massas":"massas","urls.pasta":"massas","urls.pate":"massas","urls.les-crepes-salees":"les-crepes-salees","urls.les-crepes-wrap":"les-crepes-wrap","urls.pizzas":"pizzas","urls.panne-di-pizza":"panne-di-pizza","urls.sobremesas":"sobremesas","urls.desserts":"sobremesas","urls.postres":"sobremesas","urls.bebidas":"bebidas","urls.boissons":"bebidas","urls.drinks":"bebidas"},"fr":{"urls.index":"/","urls.menu":"menu","urls.entradas":"entradas","urls.starters":"entradas","urls.entrees":"entradas","urls.saladas":"saladas","urls.salads":"saladas","urls.salades":"saladas","urls.massas":"massas","urls.pasta":"massas","urls.pate":"massas","urls.les-crepes-salees":"les-crepes-salees","urls.les-crepes-wrap":"les-crepes-wrap","urls.pizzas":"pizzas","urls.panne-di-pizza":"panne-di-pizza","urls.sobremesas":"sobremesas","urls.desserts":"sobremesas","urls.postres":"sobremesas","urls.bebidas":"bebidas","urls.boissons":"bebidas","urls.drinks":"bebidas"}},"defaultLocale":"pt","prefix":"urls."},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5H4V228","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://media.pizzarte.com/graphql","perPage":50,"schema":{"perPage":50,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000,"requestConcurrency":15,"previewRequestConcurrency":5},"type":{"MediaItem":{"localFile":{"excludeByMimeTypes":["video/mp4","video/mov"],"maxFileSizeBytes":15728640,"requestConcurrency":100},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
